import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

const Header = styled.h1`
    width: 100%;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 60px;
    color: ${props => props.headerColor};
`;

const StyledRow = styled.div`
    justify-content: ${props => props.flexCenter ? 'center' : 'flex-start'};
`;

const Row = ({id, children, className, flexCenter}) => (
    <div id={id} className={className}>
        <div className="container">
            <StyledRow className="row" flexCenter={flexCenter}>{children}</StyledRow>
        </div>
    </div>
);

const SectionRow = styled(Row)`
    display: flex;
    color: ${props => props.color};
    text-align: ${props => props.center ? 'center' : 'left'};
    padding: 100px 20px;
    padding-top: ${props => props.paddingTop ? `${props.paddingTop}px` : '100px'};
    align-items: center;
    background-color: ${props => props.backgroundColor};
`;


export default ({id, header, headerColor, color, backgroundColor, center, flexCenter, paddingTop, children}) => {
    return (
        <SectionRow
            id={id}
            headerColor={headerColor}
            color={color}
            backgroundColor={backgroundColor}
            center={center}
            flexCenter={flexCenter}
            paddingTop={paddingTop}
        >
            {header ? <Header headerColor={headerColor}>{header}</Header> : null}
            {children}
        </SectionRow>
    )
}