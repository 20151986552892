import React, { useState } from 'react';
import axios from 'axios';
const qs = require('qs');

export default () => {

    const [sent, setSent] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = qs.stringify({
            email,
            phone,
            message,
            'form-name': 'contact'
        });

        const response = await axios.post('contact', data, {
            headers: {
                'content-type': 'application/x-www-form-urlencoded' 
            }
        });
        
        setSent(true);
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    }

    const form = (
        <form className="contact" name="contact" data-netlify="true" onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="email">Adres email*</label>
                <input type="email" name="email" className="form-control" id="email" aria-describedby="emailHelp" required={true} value={email} onChange={handleEmailChange} />
            </div>
            <div className="form-group">
                <label htmlFor="phone">Numer telefonu</label>
                <input type="text" name="phone" className="form-control" id="phone" value={phone} onChange={handlePhoneChange} />
            </div>
            <div className="form-group">
                <label htmlFor="message">Wiadomość*</label>
                <textarea className="form-control" name="message" id="message" rows="5" required={true} value={message} onChange={handleMessageChange}></textarea>
            </div>
            <input type="submit" className="btn btn-primary" style={{ width: '100%', letterSpacing: 5, backgroundColor: '#1082D8', marginTop: 50 }} value="WYŚLIJ" />
            <input type="hidden" name="form-name" value="contact" />
        </form>
    );

    const thankYou = (
        <>
            <h2>Dziękujemy</h2>
            <p>Twoja wiadomość została wysłana do naszego serwisu. Wkrótce skontaktujemy się z Tobą.</p>
        </>
    );

    return (
        <>
            {sent ? thankYou : form}
        </>
    );
}