import React, {useState} from 'react';
import clsx from 'clsx';

export default () => {

    const [open, setOpen] = useState(false);

    const handleOnClick = () => {
        setOpen(false);
    }

    const handleOpenMenu = () => {
        setOpen(true);
    }

    const menuButton = (
        <svg onClick={handleOpenMenu} width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-list menu-icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
        </svg>
    )

    return (
        <>
           {open ? null : menuButton}
            <div className={clsx("mobile-nav", open ? "d-flex" : "hidden")}>
                <svg onClick={handleOnClick} width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-x-circle mobile-nav-close" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path fillRule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
                <ul>
                    <li onClick={handleOnClick}><a href="#services">Usługi</a></li>
                    <li onClick={handleOnClick}><a href="#about">O firmie</a></li>
                    <li onClick={handleOnClick}><a href="#gwaranty">Pakiety serwisowe</a></li>
                    <li onClick={handleOnClick}><a href="#cert">Certyfikaty</a></li>
                    <li onClick={handleOnClick}><a href="#location">Zasięg</a></li>
                    <li onClick={handleOnClick}><a href="#contact">Kontakt</a></li>
                </ul>
            </div>
        </>
    )
}