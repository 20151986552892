import React from "react";
import { Cert, Certs, CompanySwiper, ContactForm, DesktopMenu, Footer, Hero, Logo, MobileMenu, ScrollToTop, Section, SectionHeader, SectionImage, ServiceItem, GwarantyForm} from '../components';
import '../assets/css/global.css';
import 'bootstrap/dist/css/bootstrap.css';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Helmet } from "react-helmet";

export default function Home() {


  const data = useStaticQuery(graphql`
    query map {
      map: file (name: { eq: "mapa" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      car: file (name: { eq: "car" }) {
        childImageSharp {
          fluid {
            src,
            ...GatsbyImageSharpFluid
          },
          fixed (height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      office: file (name: { eq: "office" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);


  return (
    <>
      <MobileMenu />
      <div className="row" style={{backgroundColor: '#EC8100', height: 600, position: 'relative', overflow: 'hidden'}}>
        <div className="col-12 d-flex">
          <Logo />
          <DesktopMenu />

          <h2 className="hero-text" style={{position: 'absolute', color: '#ffffff', fontSize: '1.5rem', top: '50%', lineHeight: 2, zIndex: 12}}>Tworzymy ogólnopolską sieć serwisową jednej z wiodących marek. Współpracujemy z siecią autoryzowanych serwisów na terenie całego kraju.</h2>

          <div className="hero" style={{position: 'absolute', top: -80, right: '-23%', width: '85%', height: 1200, borderRadius: '50%', overflow:'hidden'}}>
            <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: 1200, zIndex: 10, opacity: .7, backgroundColor: '#1082D8'}}></div>
            <Img fixed={data.car.childImageSharp.fixed} />
          </div>
        </div>
      </div>
      <main>
        <Section
          id="services"
          header="Usługi"
          headerColor="#1082D8"
        >
          <p style={{textAlign: 'center', width: '100%'}}>Świadczymy kompleksowe usługi związane z serwisem sprzętu AGD oraz jego dystrybucją.</p>
          <div className="row services justify-content-center mt-4">
            <div className="col-12 col-md-2 m-2">
              <p>Naprawy gwarancyjne i pogwarancyjne sprzętu AGD</p>
            </div>
            <div className="col-12 col-md-2 m-2">
              <p>Sprzedaż sprzętu AGD domowego i do zastosowań profesjonalnych</p>
            </div>
            <div className="col-12 col-md-2 m-2">
              <p>Sprzedaż oryginalnych części zamiennych</p>
            </div>
            <div className="col-12 col-md-2 m-2">
              <p>Montaż i serwis klimatyzacji</p>
            </div>
            <div className="col-12 col-md-2 m-2">
              <p>Pakiety napraw</p>
            </div>
          </div>
        </Section>
        <Section
          id="about"
          header="O firmie"
          headerColor="#ffffff"
          color="#ffffff"
          backgroundColor="#1082D8"
        >
          <div className="col-md-12 col-lg-6">
            <p>Świadczymy kompleksowe usługi w zakresie naprawy gwarancyjnej i pogwarancyjnej sprzętu AGD. Jesteśmy autoryzowanym serwisem wiodących producentów, takich jak <strong>Sharp, Vestfrost, Toshiba, Jvc, Finlux, KERNAU, TELEFUNKEN, QILIVE, HITACHI, FALMEC, LIEBHERR</strong>, Whirlpool, Polar, Carrier czy Helkama.</p>
            <p>Zajmujemy się dystrybucją sprzętu AGD, zarówno domowego jak i do zastosowań profesjonalnych. Posiadamy w sprzedaży oryginalne części zamienne do wszystkich marek najpopularniejszych na polskim rynku.</p>
            <p>Zapoznaj się ze szczegółami dotyczącymi zakresu oferowanych przez nas usług.</p>
          </div>
          <div className="col-md-12 col-lg-6">
            <div style={{borderRadius: 30, overflow: 'hidden'}}>
              <Img fluid={data.office.childImageSharp.fluid} />
            </div>
          </div>
        </Section>
        <Section>
          <div className="col-md-12 col-lg-6">
            <div style={{borderRadius: 30, overflow: 'hidden'}}>
              <Img fluid={data.car.childImageSharp.fluid} />
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <p>Na pierwszym miejscu zawsze stawiamy zadowolenie Klienta. Zapewniamy najwyższą jakość świadczonych usług. Na uznanie Klientów pracujemy nieprzerwanie od blisko 30 lat. Przyznawane nam nagrody i odznaczenia wielokrotnie potwierdziły nasze zaangażowanie i solidność.
  </p>
            <p>Posiadamy bardzo dobrze wyposażone zaplecze techniczne. Stosujemy nowoczesne narzędzia i technologie. Stawiamy tylko na wyspecjalizowany, sprawdzony personel. Nasi pracownicy tworzą doskonale wykwalifikowany zespół fachowców, będących do Twojej dyspozycji.</p>
          </div>
        </Section>
        <Section
          header="Zaplecze techniczne"
          color="#ffffff"
          backgroundColor="#EC8100"
          center={true}
        >
          <p>Dokładamy wszelkich starań, aby oferowane przez nas usługi świadczone były przy wykorzystaniu najnowocześniejszych dostępnych narzędzi i technologii. Należą do nich m.in. profesjonalne stacje do napełniania klimatyzacji oraz S-moduły do programowania i stacje do odzysku czynników chłodniczych oraz profesjonalne narzędzia diagnostyczne do naprawy RTV.</p>
          <p>Specjaliści z naszej firmy posiadają wieloletnie doświadczenie w branży AGD i RTV. Nasi pracownicy tworzą doskonale wykwalifikowany zespół fachowców.
</p>
          <p>Nasze usługi świadczymy nie tylko w siedzibie firmy, ale także na miejscu, u Klienta. Wszystkie posiadane przez nas samochody są bardzo dobrze wyposażone i wyraźnie oznakowane.</p>
        </Section>
        <Section
          header="Autoryzowany serwis"
          headerColor="#1082D8"
          center={true}>
            <strong style={{textAlign: 'center', width: '100%'}}>Wykonujemy naprawy gwarancyjne i pogwarancyjne sprzętu wiodących producentów dostępnych na polskim rynku:</strong>
          <CompanySwiper />
        </Section>
        <Section
          id="gwaranty"
          header="Zapytaj o pakiet serwisowy!"
          headerColor="#1082D8"
          center={false}
          flexCenter={true}
          paddingTop={1}
        >
          <GwarantyForm />
        </Section>
        <Section
          id="cert"
          header="Certyfikaty i wyrónienia"
          color="#ffffff"
          backgroundColor="#1082D8"
        >
          <Certs />
        </Section>
        <Section
          id="location"
          header="Mapa zasięgu"
          headerColor="#1082D8"
        >
          <div className="col-md-6" style={{fontWeight: 600}}>
            <p className="map-text">Zasięgiem naszych usług objętych jest kilkanaście powiatów województwa łódzkiego.</p>
            <p>Cały obszar podlega pod Zduńską Wolę.</p>
          </div>
          <div className="col-md-6">
            <div style={{borderRadius: 20}}>
              <Img fluid={data.map.childImageSharp.fluid} />
            </div>
          </div>
        </Section>
        <Section
          id="contact"
          backgroundColor="#EC8100"
          color="#ffffff">
            <div className="col-md-6 contact-info" style={{display: 'flex', flexDirection: 'column', alignItemsL: 'center', flexWrap: 'wrap'}}>
              <div>
                <h2>Kontakt</h2>
                <p>SERWIS AGD TOMASZEWSKI PAWEŁ</p>
                <p>NIP: 829-104-56-25</p>
                <p>ul. Zduńska 39</p>
                <p>98-220 Zduńska Wola</p>
              </div>
              <div style={{marginTop: 50}}>
                <p>tel/fax: 43 823 26 65</p>
                <p>tel/fax: 43 825 30 21</p>
              </div>
            </div>
            <div className="col-md-6">
              <ContactForm />
            </div>
        </Section>
      </main>
      <footer className="footer">
        <span>Copyright ©{new Date().getFullYear()} Serwis AGD, Paweł Tomaszewski. Wszelkie prawa zastrzeżone.</span>
        <span className="studio">wykonanie: <a href="https://wavynet.com">wavynet</a></span>
      </footer>

    </>
  )
}
