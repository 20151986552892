import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

export default () => {

    
    const data = useStaticQuery(graphql`
    query {
        allFile (filter: { name: { in: ["cert1", "cert2", "cert3", "cert4"] }}) {
            edges {
                node {
                    childImageSharp {
                        fluid{
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }`);

    console.log(data);
    
    return (
        <div className="row" style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
            {data.allFile.edges.map((file, index) => (
                <div key={index} className="col-md-6 col-lg-3" >
                    <Img fluid={file.node.childImageSharp.fluid} />
                </div>
            ))}

            
        </div>
    );
}



// export const query = graphql`
//     query {
//         allFile (filter: { name: { in: ["cert1", "cert2", "cert3", "cert4", "office"] }}){
//             edges {
//                 node {
//                     childImageSharp {
//                         fixed(width: 100, height: 200) {
//                             ...GatsbyImageSharpFixed
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `