import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import LogoSvg from '../assets/images/logo.svg';

export default () => {

    // const data = useStaticQuery(graphql`
    //     query {
    //         file (name: { eq: "logo" }) {
    //             childImageSharp {
    //                 fluid {
    //                     ...GatsbyImageSharpFluid
    //                 }
    //             }
    //         }
    //     }
    // `);
    
    return (
        <div style={{cursor: 'pointer', width: 100, position: 'absolute', top: 30, left: 50}}>
            <LogoSvg />
        </div>
    )
}