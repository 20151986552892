import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';



const SwiperContainer = styled.div`
    display: flex;
    width: 100%;
    height: 200px;
`

export default () => {

    SwiperCore.use([Autoplay]);

    const data = useStaticQuery(graphql`
        query {
            allFile (filter: { name: { in: ["jvc", "toschiba", "vestfrost", "sharp", "finlux"] }}) {
                edges {
                    node {
                        childImageSharp {
                            fixed (width: 200) {
                                src
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
    `);

    
    return (
        <SwiperContainer>
            <Swiper
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false
                }}
                loop={true}
                spaceBetween={10}
                slidesPerView={4}
                breakpoints={{
                    0: {
                        slidesPerView: 1
                    },
                    800: {
                        slidesPerView: 2
                    },
                    1200: {
                        slidesPerView: 3
                    },
                    1400: {
                        slidesPerView: 4
                    }
                }}>
                <SwiperSlide style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} key={1}><img src="/jvc.png" width="120" /></SwiperSlide>
                <SwiperSlide style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} key={2}><img src="/toschiba.png" width="200" /></SwiperSlide>
                <SwiperSlide style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} key={3}><img src="/vestfrost.png" width="200" /></SwiperSlide>
                <SwiperSlide style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} key={4}><img src="/sharp.png" width="200"/></SwiperSlide>
                <SwiperSlide style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} key={5}><img src="/finlux.png" width="200" /></SwiperSlide>

            </Swiper>
        </SwiperContainer>
    )
}