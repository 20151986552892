import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
const qs = require('qs');


export default () => {

    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [selectedDuration, setSelectedDuration] = useState(1);
    const [selectedDevices, setSelectedDevices] = useState(3);    
    const [total, setTotal] = useState(0);
    const [sent, setSent] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = qs.stringify({
            email,
            phone,
            devices: selectedDevices,
            duration: selectedDuration,
            'form-name': 'gwaranty'
        });

        const response = await axios.post('gwaranty', data, {
            headers: {
                'content-type': 'application/x-www-form-urlencoded' 
            }
        });
        
        setSent(true);
    }

    useEffect(() => {
        setTotal(selectedDuration * selectedDevices * 162);
    }, [selectedDevices, selectedDuration]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    }

    const handleSelectedDevicesChange = (event) => {
        setSelectedDevices(event.target.value);
    }

    const handleSelectedDuratiobnChange = (event) => {
        setSelectedDuration(event.target.value);
    }

    const handleShowFormClicked = () => {
        setSent(false);
    }

    const StyledButton = styled.button`
        background-color: #ffffff;
        padding: 15px;
        border: 1px solid #cccccc;
    `

    const form = (
        <>
            <p>Prześlij poniższy formularz, a skontaktuje się z Tobą nasz przedstawiciel.</p>
            <form className="gwaranty" name="gwaranty" data-netlify="true" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Adres email*</label>
                    <input type="email" name="email" className="form-control" id="email" aria-describedby="emailHelp" required={true} onChange={handleEmailChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Numer telefonu</label>
                    <input type="text" name="phone" className="form-control" id="phone" value={phone} onChange={handlePhoneChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="duration">Okres</label>
                    <select type="text" name="duration" className="form-control" id="duration" value={selectedDuration} onChange={handleSelectedDuratiobnChange} >
                        <option value={1}>1 rok</option>
                        <option value={2}>2 lata</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="devices">Ilość sprzętu</label>
                    <select type="text" name="devices" className="form-control" id="devices" value={selectedDevices} onChange={handleSelectedDevicesChange} >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                    </select>
                </div>
                <div className="form-group">
                    <span>Przybliżony koszt pakietu: <strong>{total} zł netto</strong></span>
                </div>
                <input type="submit" className="btn btn-primary" style={{ width: '100%', letterSpacing: 5, backgroundColor: '#1082D8', marginTop: 50 }} value="WYŚLIJ" />
                <input type="hidden" name="form-name" value="gwaranty" />
            </form>
        </>
    )

    const thankYou = (
        <>
            <p>Dziękujemy za przesłanie formularza. Niedługo skontaktuje się z Tobą nasz przedstawiciel.</p>
            <StyledButton type="button" onClick={handleShowFormClicked}>Wyślij ponownie</StyledButton>
        </>
    )

    return (
        <div className="col-md-8">
            <p>Nasza firma oferuje pakiet serwisowy, który obejmuje nielimitowane naprawy sprzętów takich jak <strong>zmywarki, pralki, lodówki, piekarniki, płyty grzewcze oraz telewizory do 50"</strong>. Oferowany pakiet serwisowy jest <strong>wielokrotnie tańszy</strong> od ubezpieczenia oferowanego w sklepach.</p>
            {sent ? thankYou : form}
        </div>
    )
}