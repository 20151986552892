import React from 'react';

export default () => {

    return (
        <ul className="nav">
            <li><a href="#services">Usługi</a></li>
            <li><a href="#about">O firmie</a></li>
            <li><a href="#gwaranty">Pakiety serwisowe</a></li>
            <li><a href="#cert">Certyfikaty</a></li>
            <li><a href="#location">Zasięg</a></li>
            <li><a href="#contact">Kontakt</a></li>
        </ul>
    )
}